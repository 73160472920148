import React from "react";
import ReactDOM from "react-dom/client";
import * as Tooltip from "@radix-ui/react-tooltip";
import random from "random";

import { App } from "./app";

import reportWebVitals from "./reportWebVitals";

import "./index.css";

const rnd = random.clone(new Date().toJSON().slice(0, 10) + "T00:00:00.000Z" + new URLSearchParams(window.location.search).get("random"));
rnd.patch();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Tooltip.Provider delayDuration={0}>
			<App />
		</Tooltip.Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
