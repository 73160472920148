/** @jsxImportSource @emotion/react */
import { forwardRef, ReactNode, useCallback, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import type { DialogContentProps } from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { css } from "@emotion/react";
import { analytics } from "../helpers/analytics";

const modalOverlayStyle = css`
	position: fixed;
	display: flex;
	flex-direction: column;
	//align-items: center;
	//justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	//display: grid;
	//place-items: center;

	background-color: rgba(0, 0, 0, .4);
	background-color: transparent;
	background-color: rgba(0, 0, 0, .6);
	backdrop-filter: blur(100px);

	overflow-y: scroll;
	z-index: 9999;
`;

const modalContentStyle = css`
	position: relative;
	padding: 32px;
	width: 568px;
	max-width: 100%;
	box-sizing: border-box;
	margin: auto;

	background-color: white;
	background-color: #071c31;
	background-color: #021222;
	background-color: var(--bg2);
	//border: solid 1px rgba(255, 255, 255, .1);
	border-radius: 8px;
	//box-shadow: 0 12px 16px rgba(0, 0, 0, .35);
	background-color: hsl(210deg 70% 9% / 70%);
	background-color: hsl(210deg 100% 12% / 80%);
	background-color: hsl(211 90% 10% / 80%);
	//backdrop-filter: blur(15px);
	//border: solid 1px rgba(255, 255, 255, .22);
	box-shadow: 0 0 20px hsl(0deg 0% 0% / 50%);

	&:focus {
		outline: none;
	}
`;

const modalHeadingStyle = css`
	display: flex;
	gap: .35em;
	margin-top: 0;
	margin-bottom: 32px;

	padding-right: 8px;

	font-size: 24px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const modalCloseStyle = css`
	position: absolute;
	top: 8px;
	right: 8px;
	border: none;
	background: none;
	padding: 8px;

	color: black;
	color: white;
	cursor: pointer;
	outline: none;

	svg {
		display: block;
	}
`;

export interface ModalContentProps extends DialogContentProps {
	heading?: ReactNode;
}

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>((props, ref) => {
	const {heading, children, ...contentProps} = props;

	return (
		<Dialog.Portal>
			<Dialog.Overlay css={modalOverlayStyle}>
				<Dialog.Content css={modalContentStyle} {...contentProps} ref={ref}>
					<Dialog.Close css={modalCloseStyle}>
						<Cross1Icon />
					</Dialog.Close>
					{heading && <h3 css={modalHeadingStyle}>{heading}</h3>}
					{children}
				</Dialog.Content>
			</Dialog.Overlay>
		</Dialog.Portal>
	);
});

ModalContent.displayName = "Modal";

export interface ModalProps extends Dialog.DialogProps {
	analyticsId?: string;
}

export const Modal = (props: ModalProps) => {
	const {analyticsId, open: openProp, onOpenChange, children, ...dialogProps} = props;

	const handleOpenChange = useCallback((open: boolean) => {
		if (openProp === undefined) {
			// not controlled from outside, otherwise handle in useEffect
			if (open && analyticsId) {
				analytics(`modal:${analyticsId}`, {type: "modal", id: analyticsId});
			}
		}

		onOpenChange?.(open);
	}, [analyticsId, openProp, onOpenChange]);

	useEffect(() => {
		if (openProp && analyticsId) {
			analytics(`modal:${analyticsId}`, {type: "modal", id: analyticsId});
		}
	}, [analyticsId, openProp]);

	return (
		<Dialog.Root open={openProp} onOpenChange={handleOpenChange} {...dialogProps}>
			{children}
		</Dialog.Root>
	);
};

export const ModalTrigger = Dialog.Trigger;
