/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { Arrow, Content, Portal, Root, TooltipProps as RadixTooltipProps, Trigger } from "@radix-ui/react-tooltip";
import { css } from "@emotion/react";

const contentStyle = css`
	padding: 4px 8px;

	font-size: 14px;
	background-color: hsl(210deg 10% 35%);
	border-radius: 4px;
	color: white;

	pointer-events: none;
	z-index: 99999;
`;

const arrowStyle = css`
	fill: hsl(210deg 10% 35%);
`;

interface TooltipProps extends RadixTooltipProps {
	content: ReactNode;
}

export const Tooltip = (props: TooltipProps) => {
	const {
		children,
		content,
		open,
		defaultOpen,
		onOpenChange,
		disableHoverableContent,
		...contentProps
	} = props;

	return (
		<Root
			open={open}
			defaultOpen={defaultOpen}
			onOpenChange={onOpenChange}
			disableHoverableContent={disableHoverableContent}
		>
			<Trigger asChild>{children}</Trigger>
			<Content css={contentStyle} side="top" align="center" {...contentProps}>
				{content}
				<Arrow css={arrowStyle} />
			</Content>
		</Root>
	);
};
