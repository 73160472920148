/** @jsxImportSource @emotion/react */
import { MutableRefObject, useCallback, useRef, useState } from "react";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import { css } from "@emotion/react";

import { ReactComponent as ReportIcon } from "../assets/report-icon.svg";

import { analytics } from "../helpers/analytics";
import { HoverRef } from "../model/hover-ref";
import { Website } from "../model/website";

import { Colors } from "./colors";
import { Modal } from "./modal";
import { ReportDialog } from "./report-dialog";
import { Tooltip } from "./tooltip";

const websiteStyle = css`
	position: relative;
`;

const linkStyle = css`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;

	[data-hovered-by=mouse] & {
		display: block;
	}
`;

const screenshotStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;

	[data-hovered-by] & {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

const infoStyle = css`
	position: absolute;
	display: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 16px;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: flex-end;

	background: linear-gradient(to top, #021222, #0212222d);
	color: white;
	border-radius: 4px;

	pointer-events: none;

	> * {
		pointer-events: all;
	}

	[data-hovered-by] & {
		display: flex;
	}
`;

const urlTitleStyle = css`
	font-size: 18px;
	font-weight: 800;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const tagsStyle = css`
	padding-right: 24px;
`;

const tagStyle = css`
	font-size: 14px;
	opacity: .9;

	&:not(:last-of-type) {
		margin-right: 6px;
	}
`;

const linkButtonStyle = css`
	position: absolute;
	display: none;
	top: 16px;
	right: 16px;
	padding: 4px 8px;
	align-items: center;

	font-size: 14px;
	background-color: white;
	border-radius: 4px;
	color: black;

	z-index: 10;

	svg {
		margin-left: 4px;
	}

	[data-hovered-by=touch] & {
		display: flex;
	}
`;

const reportButtonStyle = css`
	position: absolute;
	display: none;
	bottom: 10px;
	right: 8px;
	padding: 8px;
	background: none;

	cursor: pointer;
	z-index: 10;

	svg {
		display: block;
		width: 12px;
		fill: coral;
	}

	[data-hovered-by] & {
		display: block;
	}
`;

export interface WebsiteCardProps {
	website: Website;
	hoverRef: MutableRefObject<HoverRef|undefined>;
}

export const WebsiteCard = (props: WebsiteCardProps) => {
	const {website, hoverRef} = props;

	const linkRef = useRef<HTMLAnchorElement>(null);
	const touchMoveRef = useRef<boolean>(false);
	const [hoveredBy, setHoveredBy] = useState<string|undefined>(undefined);
	const [showReportDialog, setShowReportDialog] = useState<boolean>(false);

	const url = website.url.replace(/https?:\/\/(www\.)?/, "");

	const setHover = useCallback((by: string) => {
		hoverRef.current?.cancel();
		hoverRef.current = {
			cancel: () => {
				setHoveredBy(undefined);
			}
		};

		setHoveredBy(by);
	}, [hoveredBy]);

	const cancelHover = useCallback(() => {
		hoverRef.current?.cancel();
		hoverRef.current = undefined;
	}, []);

	const handlePointerEnter = useCallback((ev: React.PointerEvent) => {
		if (ev.pointerType === "mouse" && hoveredBy !== "mouse") {
			setHover("mouse");
		}
	}, [hoveredBy, setHover]);

	const handlePointerLeave = useCallback((ev: React.PointerEvent) => {
		if (ev.pointerType === "mouse") {
			cancelHover();
		}
	}, [cancelHover]);

	const handleLinkClicked = useCallback((ev: React.TouchEvent|React.MouseEvent) => {
		if (!touchMoveRef.current) {
			ev.preventDefault();
			analytics("visit", {type: "visit", websiteId: website.id, url: website.url});
			window.open(website.url, "_blank");
		}

		touchMoveRef.current = false;
	}, [website]);

	const handleTouchMove = useCallback(() => {
		touchMoveRef.current = true;
	}, []);

	const handleTouchEnd = useCallback(() => {
		if (!touchMoveRef.current && hoveredBy !== "touch") {
			setHover("touch");
		}

		touchMoveRef.current = false;
	}, [hoveredBy, setHover, cancelHover]);

	const handleReportButtonClicked = useCallback((ev: React.TouchEvent|React.MouseEvent) => {
		if (!touchMoveRef.current) {
			ev.preventDefault();
			setShowReportDialog(true);
		}

		touchMoveRef.current = false;
	}, []);

	return (
		<>
			<div
				css={websiteStyle}
				onPointerEnter={handlePointerEnter}
				onPointerLeave={handlePointerLeave}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				data-hovered-by={hoveredBy}
			>
				<AspectRatio.Root ratio={5 / 4}>
					<img css={screenshotStyle} src={`/assets/${website.id}.webp`} alt={url} loading="lazy" />
				</AspectRatio.Root>
				<a
					css={linkStyle}
					href={website.url}
					ref={linkRef}
					onClick={handleLinkClicked}
				/>
				<div css={infoStyle}>
					<div css={urlTitleStyle}>{url}</div>
					<div css={tagsStyle}>
						{website.tags.map(tag =>
							<span key={tag} css={tagStyle}>#{tag} </span>)
						}
					</div>
					<Colors colors={website.colors.slice(0, 5)} />
				</div>
				<button css={linkButtonStyle} onTouchEnd={handleLinkClicked}>
					visit website <ArrowTopRightIcon />
				</button>
				<Tooltip content="Report website" disableHoverableContent>
					<button
						css={reportButtonStyle}
						onTouchEnd={handleReportButtonClicked}
						onClick={handleReportButtonClicked}
					>
						<ReportIcon />
					</button>
				</Tooltip>
			</div>
			<Modal open={showReportDialog} onOpenChange={setShowReportDialog} analyticsId="report">
				{showReportDialog && <ReportDialog website={website} />}
			</Modal>
		</>
	);
};
