/** @jsxImportSource @emotion/react */
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Content, Root, PopoverProps as RadixPopoverProps, Trigger, Arrow, Portal } from "@radix-ui/react-popover";
import { css } from "@emotion/react";

import { analytics } from "../helpers/analytics";

const contentStyle = css`
	padding: 4px 8px;

	font-size: 14px;
	background-color: hsl(210deg 10% 35%);
	border-radius: 4px;
	color: white;

	pointer-events: none;
	z-index: 99999;
	outline: none;
`;

const arrowStyle = css`
	fill: hsl(210deg 10% 35%);
`;

interface PopoverProps extends RadixPopoverProps {
	content: ReactNode;
	enabled?: boolean;
	offset?: number;
	analyticsId?: string;
}

export const Popover = (props: PopoverProps) => {
	const {
		children,
		content,
		enabled,
		open,
		defaultOpen,
		onOpenChange,
		offset,
		analyticsId,
		...contentProps
	} = props;

	const [internalOpen, setInternalOpen] = useState<boolean>(defaultOpen || false);

	const handleOpenChange = useCallback((open: boolean) => {
		if (open && analyticsId) {
			analytics(`popover:${analyticsId}`, {type: "popover", id: analyticsId});
		}

		onOpenChange?.(open) || setInternalOpen(open);
	}, [enabled, analyticsId, onOpenChange]);

	useEffect(() => {
		if (!open && !internalOpen) {
			return;
		}

		const handleScroll = () => handleOpenChange(false);
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [open, internalOpen]);

	return (
		<Root
			open={enabled === false ? false : (open !== undefined ? open : internalOpen)}
			defaultOpen={defaultOpen}
			onOpenChange={handleOpenChange}
		>
			<Trigger asChild onMouseEnter={() => handleOpenChange(true)} onMouseLeave={() => handleOpenChange(false)}>{children}</Trigger>
			<Portal>
				<Content css={contentStyle} side="top" align="center" sideOffset={offset} {...contentProps}>
					{content}
					<Arrow css={arrowStyle} />
				</Content>
			</Portal>
		</Root>
	);
};
