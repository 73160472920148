/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import avatar from "../assets/avatar.png";

import { ModalContent } from "./modal";

import { ReactComponent as ReportIcon } from "../assets/report-icon.svg";

const avatarStyle = css`
	float: left;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin-right: 16px;
`;

const reportIconStyle = css`
	height: 13px;
	margin-left: 4px;
	fill: white;
	position: relative;
	top: 1px;
	opacity: .85;
`;

const disclaimerStyle = css`
	font-size: 12px;
	text-align: right;
	margin-top: 32px;
	margin-bottom: 0;
`;

export const AboutDialog = () => {
	return (
		<ModalContent heading="About">
			<p>
				<a href="https://x.com/uiii"><img src={avatar} css={avatarStyle} /></a> Hello, my name is Richard Jedlička and I am the author of&nbsp;this website.
			</p>
			<hr />
			<p>
				I created <a href="/">Homepage Gallery</a> for us who like to have an inspiration when building our own projects.
				Feel free to get inspired as well.
			</p>
			<p>
				Explore the showcase of websites from all over the internet on various topics.
				Websites are categorized with tags and color characteristics (filter feature coming soon).
			</p>
			<p>
				All screenshots are obtained automatically and updated regularly.
				Making a perfect screenshot is not an easy task.
				If you find some faulty ones, report them please <ReportIcon css={reportIconStyle} />.
				It will help me a lot, thanks.
			</p>
			<hr />
			<p>
				If you have any suggestions or want to ask something,
				contact me at <a href="mailto:support@homepage.gallery">support@homepage.gallery</a>.
			</p>
			<p>You can also follow <a href="https://x.com/uiii">@uiii</a> on 𝕏.</p>
			<p css={disclaimerStyle}>All screenshots © their respective owners</p>
		</ModalContent>
	);
};
