/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

import { analytics } from "../helpers/analytics";
import { Website } from "../model/website";

import { ModalContent } from "./modal";

const reportDialogStyle = css`
	p {
		margin-bottom: 0;
	}

	ul {
		padding: 0;
		list-style: none;

		li {
			margin: 0;

			:not(:first-of-type) {
				border-top: solid 1px rgba(0, 0, 0, .1);
				border-top: solid 1px rgba(255, 255, 255, .1);
			}

			a {
				display: block;
				padding: 12px 0;
				font-size: 14px;
				font-weight: 600;
			}

			&:hover {
				border-top-color: transparent;

				& + li {
					border-top-color: transparent;
				}

				a {
					background-color: rgba(0, 0, 0, .05);
					background-color: #004d9b;
					background-color: #17497a;
					background-color: rgba(255, 255, 255, .075);
					color: white;
				}
			}
		}
	}

	textarea {
		display: block;
		width: 100%;
		margin: 8px 0;
		resize: none;
	}

	[data-class=disclaimer] {
		font-size: 12px;
		text-align: right;
		margin-top: 32px;
		margin-bottom: 0;
	}
`;

const headingLinkStyle = css`
	flex: 1 1 auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const backButtonStyle = css`
	position: absolute;
	display: flex;
	align-items: center;
	top: 8px;
	left: 14px;
	border: none;
	background: none;
	padding: 8px;
	//margin-top: -14px;
	//margin-right: -16px;

	color: black;
	color: white;

	cursor: pointer;
	outline: none;

	svg {
		display: block;
		margin-right: 6px;
	}

	& + * {
		margin-top: 24px;
	}
`;

export interface ReportDialogProps {
	website: Website;
}

export const ReportDialog = (props: ReportDialogProps) => {
	const {website} = props;

	const [state, setState] = useState<string>("default");
	const [customReason, setCustomReason] = useState<string>("");

	const handleReason = useCallback((event: React.MouseEvent) => {
		event.preventDefault();
		const el = event.currentTarget;
		const reason = el.getAttribute("data-reason");

		if (reason === "removal-request") {
			analytics(`report:${reason}`, {type: "report", reason, websiteId: website.id});
			setState(reason);
		} else if (reason === "custom") {
			setState(reason);
		} else if (reason) {
			analytics(`report:${reason}`, {type: "report", reason, websiteId: website.id});
			setState("thanks");
		}
	}, [website]);

	const handleCustomSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();

		analytics("report:custom", {type: "report", reason: "custom", detail: customReason, websiteId: website.id});
		setState("thanks");
	}, [customReason]);

	const handleBack = useCallback(() => {
		setState("default");
	}, []);

	const url = website.url.replace(/https?:\/\/(www\.)?/, "");

	if (state === "removal-request") {
		return (
			<ModalContent css={reportDialogStyle}>
				<button css={backButtonStyle} onClick={handleBack}>
					<ChevronLeftIcon /> back
				</button>
				<p>
					If you would like to request the website's removal from the gallery,
					please send a message to <a href="mailto:support@homepage.gallery">support@homepage.gallery</a>
				</p>
			</ModalContent>
		);
	}

	if (state === "custom") {
		return (
			<ModalContent css={reportDialogStyle}>
				<button css={backButtonStyle} onClick={handleBack}>
					<ChevronLeftIcon /> back
				</button>
				<form onSubmit={handleCustomSubmit}>
					<p>Please, write a short description of the reason:</p>
					<textarea
						name="custom-reason"
						rows={3}
						value={customReason}
						onChange={(ev) => setCustomReason(ev.target.value)}
					/>
					<button type="submit" disabled={!customReason}>Submit</button>
				</form>
			</ModalContent>
		);
	}

	if (state === "thanks") {
		return (
			<ModalContent css={reportDialogStyle} heading="Thank you">
				<p>
					Thank you for your feedback. This will help me make this gallery even better.
				</p>
			</ModalContent>
		);
	}

	return (
		<ModalContent
			css={reportDialogStyle}
			heading={
				<>Report website <a href={website.url} css={headingLinkStyle}>{url}</a></>
			}
		>
			<p>Please select a reason why do you want to report it:</p>
			<ul>
				<li>
					<a href="#" onClick={handleReason} data-reason="screenshot-broken">Screenshot is broken</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="screenshot-outdated">Screenshot is outdated</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="popups">Pop-ups in the screenshot (cookies, chatbot, ...)</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="domain-for-sale">Domain is for sale</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="website-broken">Website doesn't work</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="bad-info">Incorrect website info (tags, ...)</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="bad-design">Doesn't fit here (unrepresentative design)</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="removal-request">I'm the website's owner and don't want it to be shown here</a>
				</li>
				<li>
					<a href="#" onClick={handleReason} data-reason="custom">Something else</a>
				</li>
			</ul>
			<p>
				If you are looking for further assistance,
				please contact me at <a href="mailto:support@homepage.gallery">support@homepage.gallery</a>.
			</p>
			<p data-class="disclaimer">All screenshots © their respective owners</p>
		</ModalContent>
	);
};
