/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

import { Color } from "../model/color";

import { Popover } from "./popover";

const colorsStyle = css`
	display: flex;
	align-items: center;
	height: 44px;
	margin-bottom: -12px;
	margin-left: -12px;
	padding: 0 12px;
	align-self: flex-start;

	> *:not(:last-of-type) {
		margin-right: 8px;
	}
`;

const colorStyle = css`
	--size: 20px;
	position: relative;
	width: var(--size);
	height: var(--size);
	padding: 0;
	border-radius: 20px;
	border: solid 1px rgba(255, 255, 255, .25);
	box-shadow: 0 0 1px white;
	cursor: default;
`;

const popoverStyle = css`
	position: relative;
	display: flex;
	align-items: center;
`;

const popoverDividerStyle = css`
	height: 16px;
	width: 1px;
	background-color: rgba(255, 255, 255, .25);
	flex: 0 0 auto;
	margin: 0 8px;
`;

export interface ColorsProps extends React.HTMLAttributes<HTMLDivElement> {
	colors: Color[];
}


export const Colors = (props: ColorsProps) => {
	const {colors} = props;

	const wrapperRef = useRef<HTMLDivElement>(null);

	const [popoverEnabled, setPopoverEnabled] = useState<boolean>(true);

	useEffect(() => {
		const disablePopover = () => setPopoverEnabled(false);
		const enablePopover = () => setPopoverEnabled(true);

		wrapperRef.current?.addEventListener("transitionstart", disablePopover);
		wrapperRef.current?.addEventListener("transitionend", enablePopover);

		return () => {
			wrapperRef.current?.removeEventListener("transitionstart", disablePopover);
			wrapperRef.current?.removeEventListener("transitionend", enablePopover);
		};
	});

	return (
		<div
			ref={wrapperRef}
			css={colorsStyle}
		>
			{colors.map((color, index) =>
				<Popover
					key={color.hex}
					content={
						<div css={popoverStyle}>
							{color.hex.toUpperCase()}
							<div css={popoverDividerStyle} />
							{(color.percent * 100).toFixed(1)}%
						</div>
					}
					offset={6}
					analyticsId="color"
					enabled={popoverEnabled}
				>
					<button css={colorStyle} style={{backgroundColor: color.hex, zIndex: colors.length - index}} />
				</Popover>
			)}
		</div>
	);
};
