export interface AnalyticsEventData {
	type: string;
}

export function analytics<T extends AnalyticsEventData>(event: string, data?: T) {
	try {
		umami.track(event, data);
	} catch(e) {
		// ignore
	}
}
