/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useRef } from "react";
import { css, CSSObject } from "@emotion/react";

import { WebsiteCard } from "./components/website-card";
import { HoverRef } from "./model/hover-ref";
import { Website } from "./model/website";

import websitesJson from "./websites.json";

import { ReactComponent as Logo } from "./logo.svg";
import { Modal, ModalTrigger } from "./components/modal";
import { AboutDialog } from "./components/about-dialog";
import { Popover } from "./components/popover";
import { analytics } from "./helpers/analytics";

const backgroundGlowStyle = css`
	--ellipse-width: 320px;
	--ellipse-height: 400px;
	--bottom: 120px;

	position: absolute;
	bottom: calc(-700px - var(--bottom));
	left: 0;
	width: 100%;
	height: 1400px;

	// color steps generated at https://larsenwork.com/easing-gradients/ with cubic-bezier(0.51, 0, 0.37, 1)
	background: radial-gradient(
		ellipse var(--ellipse-width) var(--ellipse-height) at center,
		hsla(210.12, 100%, 50%, 0.2) 0%,
		hsla(210.12, 100%, 50%, 0.197) 9.4%,
		hsla(210.12, 100%, 50%, 0.19) 17.3%,
		hsla(210.12, 100%, 50%, 0.179) 23.9%,
		hsla(210.12, 100%, 50%, 0.165) 29.6%,
		hsla(210.12, 100%, 50%, 0.148) 34.6%,
		hsla(210.12, 100%, 50%, 0.13) 39.1%,
		hsla(210.12, 100%, 50%, 0.11) 43.4%,
		hsla(210.12, 100%, 50%, 0.09) 47.7%,
		hsla(210.12, 100%, 50%, 0.07) 52.3%,
		hsla(210.12, 100%, 50%, 0.052) 57.4%,
		hsla(210.12, 100%, 50%, 0.035) 63.3%,
		hsla(210.12, 100%, 50%, 0.021) 70.3%,
		hsla(210.12, 100%, 50%, 0.01) 78.6%,
		hsla(210.12, 100%, 50%, 0.003) 88.4%,
		hsla(210.12, 100%, 50%, 0) 100%
	);


	@media (min-width: 900px) {
		--ellipse-width: 640px;
		--ellipse-height: 400px;
		--bottom: 120px;
	}

	@media (min-width: 1300px) {
		--ellipse-width: 960px;
		--ellipse-height: 440px;
		--bottom: 180px;
	}

	@media (min-width: 1700px) {
		--ellipse-width: 1280px;
		--ellipse-height: 650px;
		--bottom: 240px;
	}

	z-index: -1;
`;

const barStyle = css`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	z-index: 998;
	background: #021222;
	border-bottom: solid 1px transparent;

	.show-bar & {
		background-color: var(--bg);
		background: linear-gradient(180deg, #021222, #071c31);
		background: #021222;
		background: #071c31;
		background: var(--bg2);
		//box-shadow: 0 0 10px black;

		background: hsl(210deg 100% 12% / 80%);
		background: hsl(210deg 100% 12%);
		background: hsl(210 100% 10%);
		//background: hsl(211 90% 10% / 80%);
		background: hsl(211 90% 10%);
		//backdrop-filter: blur(5px);
		border-color: rgba(255, 255, 255, .122);
		box-shadow: 0 0 20px hsl(0deg 0% 0% / 50%);
	}

	padding-right: var(--removed-body-scroll-bar-size);

	/*&::after {
		content: '';
		display: block;
		width: var(--removed-body-scroll-bar-size);
	}*/
`;

const barContentStyle = css`
	max-width: 1630px;
	margin: 0 auto;
	padding: 0 16px;
	box-sizing: border-box;
	height: 56px;

	display: flex;
	align-items: center;
`;

const barLogoStyle = css`
	position: relative;
	//left: -100px;
	margin-right: 12px;

	transition: left .4s cubic-bezier(.47,1.64,.41,.8);;

	> svg {
		display: block;
		width: 20px;
		height: 20px;
	}

	.show-bar & {
		left: 0;
	}
`;

const barBrandStyle = css`
	position: relative;
	display: flex;
	top: -2px;
	//left: -32px;
	font-family: 'Titillium Web', sans-serif;
	font-size: 24px;
	font-weight: 700;

	align-items: center;

	color: var(--color);

	top: 0;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: .4;

	transition: left .2s linear;

	.show-bar & {
		left: 0;
	}
`;

const betaStyle = css`
	margin-left: 12px;
	padding: 0px 4px;
	padding-top: 1px;

	border: solid 1px #ffffff5e;
	border-radius: 4px;
	font-family: 'Montserrat';
	font-size: 10px;
	font-weight: 500;

	text-transform: uppercase;

	opacity: .75;
`;

const barNavStyle = css`
	margin-left: auto;
	color: hsl(210 10% 75% / 1);
	color: var(--color);

	> * {
		font-weight: 500;
		cursor: pointer;
	}
`;

const filterStyle = css`
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
	box-sizing: border-box;

	z-index: 999;

	background: var(--bg2);
	//background: hsl(210deg 100% 12% / 80%);
	background: hsl(211 90% 10%);
	//backdrop-filter: blur(5px);
	border-top: solid 1px rgba(255, 255, 255, .22);
	box-shadow: 0 0 20px hsl(0deg 0% 0% / 50%);

	color: #ffffff81;

	font-size: 15px;

	line-height: 42px;

	@media (min-width: 900px) {
		top: 0;
		left: 50%;
		bottom: auto;
		transform: translateX(-50%);
		width: auto;
		line-height: 56px;

		background: none;
		border-top: none;
		box-shadow: none;
		backdrop-filter: unset;
	}
`;

const heroStyle = css`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	text-align: center;
	box-sizing: border-box;

	color: var(--color);

	height: 430px;
	padding-top: 100px;
	padding-bottom: 90px;

	@media (min-width: 900px) {
		height: 620px;
		padding-bottom: 120px;
	}
`;

const heroHeadingStyle = css`
	font-family: "Montserrat";
	font-weight: 800;
	font-size: 30px;
	margin: 32px 16px;

	line-height: 1.05;
	letter-spacing: 1px;
	text-transform: capitalize;

	> span {
		color: transparent;

		background-image: linear-gradient(to right, rgb(232, 121, 249), rgb(14, 165, 233));
		background-clip: text;
	}

	@media (min-width: 900px) {
		font-size: 69px;
	}
`;

const heroTextStyle = css`
	font-size: 14px;
	margin: 24px;
	margin-top: 0;
	max-width: 22em;
	opacity: .75;

	@media (min-width: 900px) {
		font-size: 20px;
		max-width: 32em;
		line-height: 1.4;
	}
`;

const disclaimerStyle = css`
	margin: 24px;
	margin-top: 0;
	max-width: 380px;

	font-size: 14px;
	opacity: .65;

	@media (min-width: 900px) {
		font-size: 16px;
	}
`;

const exploreButtonStyle = css`
	margin: 24px;
	margin-bottom: 0;
	padding: 16px 42px;

	//background-color: white;
	//color: #021222;
	border: solid 1px rgba(255, 255, 255, 0.15);
	border-radius: 28px;
	background-color: transparent;
	background-image: linear-gradient(100deg, rgb(232 121 249 / 5%), rgb(14 165 233 / 5%));
	color: white;

	font-size: 18px;
	font-weight: 600;

	cursor: pointer;
`;

const heroLogoStyle = css`
	position: relative;

	> svg {
		display: block;
		width: 60px;
		height: 60px;
	}

	@media (min-width: 900px) {
		> svg {
			width: 80px;
			height: 80px;
		}
	}

	.show-bar & {
		visibility: hidden;
	}
`;

function gridColumns(columns: number) {
	const style: CSSObject = {
		gridTemplateColumns: `repeat(${columns}, minmax(auto, 400px))`,
	};

	for (let i = 0; i < columns; ++i) {
		style[`> :nth-of-type(${columns}n - ${i})`] = {
			position: "relative",
			top: `calc(${3 * 60 * Math.pow(Math.floor(Math.abs(i - columns / 2 + 0.5)) / 3, 1)}px * (1 - var(--scroll)) * var(--offset-direction))`
		};
	}

	return style;
}

const gridStyle = css`
	display: grid;
	grid-auto-rows: auto;
	grid-gap: 10px;
	justify-content: center;
	padding: 10px;
	margin: 0 auto;

	overflow: hidden;

	${gridColumns(1)}

	@media (min-width: 900px) {
		${gridColumns(2)}
	}

	@media (min-width: 1300px) {
		${gridColumns(3)}
	}

	@media (min-width: 1700px) {
		${gridColumns(4)}
	}

	/*@media (min-width: 2100px) {
		${gridColumns(5)}
	}*/
`;

const websitesCountStyle = css`
	display: inline-block;
	text-decoration-line: underline;
	text-decoration-style: dotted;
	text-decoration-thickness: 1px;
	color: inherit;
	font-size: inherit;
	background: none;
	padding: 0;
`;

export const App = () => {
	const hoverRef = useRef<HoverRef>();

	const websites = useMemo(() => {
		const websites = [...websitesJson as Website[]].map(it => ({
			...it,
			sortWeight: Math.random()
		}));

		websites.sort((a, b) => a.sortWeight - b.sortWeight);

		return websites;
	}, []);

	const handleExploreClick = useCallback(() => {
		analytics("click:explore");

		const bar = document.getElementById("bar")!;
		const hero = document.getElementById("hero")!;

		window.scrollTo({
			left: 0,
			top: hero.clientHeight - bar.clientHeight,
			behavior: "smooth"
		});
	}, []);

	const handleScroll = useCallback(() => {
		const scroll = Math.min(1, window.scrollY / 500.0);
		document.documentElement.style.setProperty("--scroll", scroll.toString());

		const bar = document.getElementById("bar")!;
		const heading = document.getElementById("hero-heading")!;

		if (heading.getBoundingClientRect().top < bar.clientHeight) {
			document.body.classList.add("show-bar");
		} else {
			document.body.classList.remove("show-bar");
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, false);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div>
			<div id="bar" css={barStyle}>
				<div css={barContentStyle}>
					<div css={barLogoStyle}>
						<Logo />
					</div>
					<a href="/" css={barBrandStyle}>homepage.gallery</a>
					<span css={betaStyle}>beta</span>
					<div css={barNavStyle}>
						<Modal analyticsId="about">
							<ModalTrigger asChild>
								<div>About</div>
							</ModalTrigger>
							<AboutDialog />
						</Modal>
					</div>
				</div>
			</div>
			<div css={filterStyle}>
				filters and sorting coming soon
			</div>
			<div id="hero" css={heroStyle}>
				<div css={backgroundGlowStyle} />
				<h1 css={heroHeadingStyle} id="hero-heading">
					Your <span data-text="web">web design</span>
					<br />
					inspiration source</h1>
				<p css={heroTextStyle}>
					Find out how other websites are designed and get an inspiration for your project.
					We don't decide what is good or not, you do.
				</p>
				<button css={exploreButtonStyle} onClick={handleExploreClick}>Explore</button>
			</div>
			<div css={gridStyle} id="grid">
				{websites.map(website => <WebsiteCard key={website.id} website={website} hoverRef={hoverRef} />)}
			</div>
		</div>
	);
};
